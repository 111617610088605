.headerBox{
   width: 100%;  
   height: 45px;
   margin: 0px;
   padding: 0px;
   position: fixed;
   top: 0px;
  /* box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.1); */
}
.userIconBox{
   width: 30px;
   height: 30px;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 5px;
   margin-left: 5px;
}
.userName{
   font-weight: 600;
   font-size: 12px;
   margin-bottom: 0px;
}
.userEmail{
   font-weight: normal;
   font-size: 10px;
   margin-top: 0px;
}
#popContainer{
   width: 270px;
   margin: 10px;
   padding: 0px;
   border-radius: 5px;
   border: 1px solid #eeeded;
   box-shadow: 0 0.46875rem 0.7875rem rgba(90,97,105,.1); 
}
#popContainer::before{
   content: "";
   width: 0px;
   height: 10px;
   overflow: hidden;
   position: absolute;
   border-color: transparent transparent #fff transparent;
   border-style: solid;
   border-width: 0px 15px 15px 15px;
   top: -15px;
   right: 15%;
   transform: rotate(0deg);
}
.popItem{
   width: 100%;
   padding: 8px 10px;
   border-radius: 5px;
   margin-bottom: 2px;
   cursor: pointer;
}
.popItem:hover{
   background-color: #eaedf2;
}
.popItemActive{
   width: 100%;
   padding: 5px 10px;
   border-radius: 5px;
   margin-bottom: 5px;
   cursor: pointer;
   background-color: thistle;
}
.sidebarContainerFull{
   width: 230px;
   height: calc(100vh - 45px);
  /* position: fixed;*/
}
.sidebarContainerClose{
   width: 55px;
   overflow: hidden;
}
.mbox_{
   width: 100%;
   height: 60px;
   border-radius: 5px;
   border-width: 1px;
   padding: 6px 3px;
   border-style: solid;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   cursor: pointer;
}
.menuControlBox{
   width: 30px;
   height: 30px;
   border-bottom-right-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 5px;
   margin-left: 0px;
}
.shortSquareBox{
   width: 15px;
   height: 15px;
   border-radius: 3px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 0px;
   margin-left: 0px;
}
#mContainer{
   width: 270px;
   margin: 10px;
   padding: 0px;
   border-radius: 5px;
   box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.1); 
}
.sidebarMenuItem{
   width: 95%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   margin: 5px 0px;
   color: gray;
   padding: 5px 2px 5px 5px; 
   border-radius: 5px;
   cursor: pointer;
}
.sidebarMenuItem:hover{
   background-color: #3a7085;
   color: #fff !important;
}
.white-on-hover:hover, .sidebarMenuItem:hover .white-on-hover{
   color: #fff !important;
}
.sidebarMenuItemActive{
   width: 95%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   margin: 5px 0px;
   padding: 5px 2px 5px 5px; 
   border-radius: 5px;
   /*border-bottom-right-radius: 20px;
   border-top-right-radius: 20px;
   border-left: 5px solid #fff; */
   cursor: pointer;
   background-color: #3a7085;
}
.sidebarMenuItemActive:hover{
   opacity: 0.9;
}
.menuSquare{
   width: 6px;
   height: 6px;
   margin-right: 8px;
}
.menuSquare:hover, .submenu:hover .menuSquare{
   background-color: #cfe4f1 !important;
}
.submenu{
   color: #549ccc;
   cursor: pointer;
}
.submenu:hover{
   color: #cfe4f1;
}
.submenu:hover > .menuSquare{
   background-color: #cfe4f1;
}