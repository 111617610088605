


/*Our mobile breaking point should be at 700px*/


@media (max-width: 700px) {
   .show-on-mobile {  display: block !important; }
   .hide-on-mobile {  display: none !important; }
   .full-width-on-mobile{width: 100% !important;}
   .no-padding-on-mobile{padding: 0px !important;}
   .no-margin-on-mobile{margin: 0px !important;}
   .content-body-bag{ padding: 1.5em 1em 1em 1em }
   .inner-content-bag {margin-top: 0em;}
   .app-header{padding: 0.5em 10px;}
   .cart-call {bottom: 57px;}
  
   ::-webkit-scrollbar{
     width: 0px;
     height: 1px;
   }

   .button{
    width: 100% !important;
  }

  .h1, h1 { font-size: 22px;}
  .h2, h2 { font-size: 20px;}
  .h3, h3 { font-size: 18px;}
  .h4, h4 { font-size: 16px;}
  .h5, h5 { font-size: 14px;}
  .h6, h6 { font-size: 12px;}
  .h7 { font-size: 10px;}
 
 }